


























import { Vue, Component, PropSync, Watch, Prop } from 'vue-property-decorator';
import UIkit from 'uikit';

@Component
export default class BaseModal extends Vue {
  @Prop({ type: Boolean, default: true }) readonly closable!: boolean;
  @PropSync('isVisible') isVisibleSync!: boolean;

  get modalOptions(): string {
    return Object.entries({
      stack: true,
      'esc-close': this.closable,
      'bg-close': this.closable,
    }).map(([key, value]) => `${key}: ${value}`)
      .join('; ');
  }

  @Watch('isVisible', { immediate: true })
  onIsVisible(isVisible: boolean) {
    const watchedElement = this.$el;
    const isElementShowing = UIkit.modal(watchedElement)
      ? UIkit.modal(watchedElement).isToggled()
      : false;
    if (isVisible && isElementShowing === false) {
      UIkit.modal(this.$el).show();
    }
    else if (isVisible === false && isElementShowing) {
      UIkit.modal(this.$el).hide();
    }
  }

  public onHidden() {
    this.isVisibleSync = false;
  }

  public mounted() {
    this.$el.addEventListener('hidden', this.onHidden);
  }
}

